import React, { useState, useEffect, useCallback } from 'react';
import 'leaflet/dist/leaflet.css';
import { Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import userIcon from '../theme/marker-icon-user.png';
import nearPoiIcon from '../theme/near-poi-icon.png';
import notDiscoveredIcon from '../theme/marker-icon-poinotdiscovered.png';
import { connect } from 'react-redux';
import ModalHint from './ModalHint';
import { modalUpdateAction } from '../store/actions/modalActions';
import { addPoiDiscoveredAction } from '../store/actions/parkourActions';
import { GlobalStateInterface, ParkourInterface, PointOfInterestInterface } from '../interfaces';
import { parkourspoiDiscoveredSelector, parkoursSelectedParkourSelector } from '../store/selectors';
import { IonButton, IonIcon, IonToast } from '@ionic/react';
import { locateSharp } from 'ionicons/icons';
import { isPlatform } from '@ionic/core';

// Style des différentes icônes
let userMarker = L.icon({
    iconUrl: userIcon,
    iconAnchor: [0, 41],
});

let DefaultIcon = L.icon({
    iconUrl: notDiscoveredIcon,
    iconAnchor: [0, 41],
    popupAnchor: [13, -37],
});

let NearPoiIcon = L.icon({
    iconUrl: nearPoiIcon,
    iconAnchor: [0, 41],
    popupAnchor: [13, -37],
});

// Typage des props
type PropsType = {
    // props
    parkour: ParkourInterface | null,
    poiDiscovered: number[],
    // actions
    modalUpdateAction: (poiHint: PointOfInterestInterface | null) => void,
    addPoiDiscoveredAction: (index: number) => void,
}

const MapMarkers: React.FC<PropsType> = (props) => {

    const [showToastLocation, setShowToastLocation] = useState(false);

    const [userPosition, setUserPosition] = useState({
        latitude: 0,
        longitude: 0,
    });
    const [followUser, setFollowUser] = useState(true);
    const [angle, setAngle] = useState<number | null>(null);
    const map = useMap();

    const onLocationFound: any = useCallback((e: any) => {
        const latitude = e.latlng.lat;
        const longitude = e.latlng.lng;
        //check to update discovered poi
        if (props.parkour === null) {
            return;
        }

        if (latitude !== userPosition.latitude || longitude !== userPosition.longitude) {
            //update user position
            setUserPosition({ latitude, longitude });
            setShowToastLocation(false);

            //change center with user position
            if (followUser) {
                map.setView([e.latlng.lat, e.latlng.lng]);
            }

            props.parkour.pointOfInterests.map(async (poi: PointOfInterestInterface, index: number) => {
                const distance = e.latlng.distanceTo([poi.latitude, poi.longitude]);
                // check if distance between user and poi is ok with configured radius
                if (distance < poi.radius) {
                    // poi not found yet
                    if (!props.poiDiscovered.includes(index)) {
                        props.addPoiDiscoveredAction(index);
                    }
                    return
                }
            })

        }
    }, [props, userPosition, followUser, map]);

    const onDeviceOrientation: any = useCallback((e: DeviceOrientationEvent) => {
        if (!isPlatform('ios') && e.alpha !== null) {
            setAngle(e.alpha)
        }
    }, []);

    let userDirectionMarker = L.divIcon({
        iconAnchor: [12, 62],
        html: angle !== null ? `<img 
        style="transform: rotate(-${(angle ? angle + 90 : 0)}deg);"
        src='assets/marker-icon-user-direction.png'>` : "",
        className: 'dummy',
    });

    useMapEvents({
        dragend: () => {
            setFollowUser(false);
        },
        zoomend: () => {
            setFollowUser(false);
        }
    });

    useEffect(() => {
        map.locate({
            watch: true,
            enableHighAccuracy: true,
        })

        map.addEventListener('locationfound', onLocationFound);
        window.addEventListener("deviceorientation", onDeviceOrientation);

        return () => {
            map.removeEventListener('locationfound', onLocationFound);
            window.removeEventListener('deviceorientation',onDeviceOrientation)
        }

    }, [map, onLocationFound,onDeviceOrientation])

    const resetCenter = () => {
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'granted') {
                map.setView([userPosition.latitude, userPosition.longitude]);
                setFollowUser(true);
                setShowToastLocation(false);
            } else if (result.state === 'denied') {
                setShowToastLocation(true);
            }
        });
    }

    return (
        <>
            <ModalHint />
            <IonToast
                isOpen={showToastLocation}
                message={'Les géolocalisation est désactivé sur votre appareil'}
                duration={0}
            />
            <IonButton
                color={followUser ? "primary" : "light"}
                style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1000 }}
                onClick={resetCenter}>
                <IonIcon icon={locateSharp}></IonIcon>
            </IonButton>
            {
                // Vérif si le state redux a bien été init avec les données récup avec internet
                props.parkour !== null &&
                // Génération des markers des POI. Si poi découvert -> marker vert + modal ouvrable, sinon marker rouge et modal non ouvrable
                props.parkour.pointOfInterests.map((poi: PointOfInterestInterface, index: number) => {
                    return (
                        <Marker
                            key={index}
                            position={[poi.latitude, poi.longitude]}
                            icon={props.poiDiscovered.includes(index) ? NearPoiIcon : DefaultIcon}
                            zIndexOffset={200}
                            eventHandlers={{
                                click: async () => {
                                    map.panTo(new L.LatLng(poi.latitude, poi.longitude));
                                    if (props.poiDiscovered.includes(index)) {
                                        props.modalUpdateAction(poi);
                                    }
                                },
                            }}
                        >
                            {!props.poiDiscovered.includes(index) ? <Popup autoPan={false}>Non découvert</Popup> : null}
                        </Marker>
                    )
                })
            }
            {/* Marker gérant la  position de l'utilisateur. S'actualise au onlocationfound */}
            <Marker position={[userPosition.latitude, userPosition.longitude]} icon={userDirectionMarker}></Marker>
            <Marker position={[userPosition.latitude, userPosition.longitude]} icon={userMarker}></Marker>
        </>
    );
};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    parkour: parkoursSelectedParkourSelector(state),
    poiDiscovered: parkourspoiDiscoveredSelector(state),
});

const mapDispatchToProps = {
    modalUpdateAction,
    addPoiDiscoveredAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapMarkers);
