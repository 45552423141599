import React from 'react';
import { Link } from 'react-router-dom';
import Style from './BtnRoundWithIcon.module.scss';

type PropsType = {
    type: string,
    clickable: boolean,
}

const BtnRoundWithIcon: React.FC<PropsType> = (props) => {

    let icon = "";
    let bgColorClass = "";
    let btnTitle = "";
    let slug = "";

    switch (props.type) {
        case 'indices':
            icon = "assets/icon-btn-indices.png";
            bgColorClass = Style.indiceColor
            btnTitle = "INDICES"
            slug = "/hint"
            break;
        case 'plan':
            icon = "assets/icon-btn-plan.png";
            bgColorClass = Style.planColor
            btnTitle = "PLAN"
            slug = "/plan"
            break;
        case 'validation':
            icon = "assets/icon-btn-validation.png";
            bgColorClass = Style.validationColor
            btnTitle = "VALIDATION"
            slug = "/validation"
            break;

        default:
            icon = "assets/icon-btn-indices.png";
            bgColorClass = Style.indiceColor
            btnTitle = "INDICES"
            slug = "#"
            break;
    }

    return (
        <div className={props.clickable ? Style.timerButtonsColumn : Style.timerButtonsColumnUnclickable}>
            <Link to={props.clickable ? slug : '#'}>
                <button className={Style.timerButton + ' ' + bgColorClass}>
                    <img src={icon} alt="button icon" />
                </button>
            </Link>
            <p>{btnTitle}</p>
        </div>
    );
}

export default BtnRoundWithIcon;