import React from 'react';
import { Link } from 'react-router-dom';
import {
    IonMenuToggle,
} from '@ionic/react';
import Style from './BtnMenuToggle.module.scss';

type PropsType = {
    type: string,
}

const BtnMenuToggle: React.FC<PropsType> = (props) => {

    let icon = "";
    let bgColorClass = "";
    let btnTitle = "";
    let slug = "";

    switch (props.type) {
        case 'timer':
            icon = "assets/icon-btn-play-color.png";
            bgColorClass = Style.timerColor
            btnTitle = "TIMER"
            slug = "/timer"
            break;
        case 'indices':
            icon = "assets/icon-btn-indices.png";
            bgColorClass = Style.indiceColor
            btnTitle = "INDICES"
            slug = "/hint"
            break;
        case 'plan':
            icon = "assets/icon-btn-plan.png";
            bgColorClass = Style.planColor
            btnTitle = "PLAN"
            slug = "/plan"
            break;
        case 'validation':
            icon = "assets/icon-btn-validation.png";
            bgColorClass = Style.validationColor
            btnTitle = "VALIDATION"
            slug = "/validation"
            break;

        default:
            icon = "assets/icon-btn-indices.png";
            bgColorClass = Style.indiceColor
            btnTitle = "INDICES"
            slug = "#"
            break;
    }
    return (
        <IonMenuToggle>
            <Link to={slug}>
                <div className={Style.menuItem}>
                    <div className={Style.itemCircle + ' ' + bgColorClass}>
                        <img className={Style.iconBtn} src={icon} alt="icon button" />
                    </div>
                    <p>{btnTitle}</p>
                </div>
            </Link>
        </IonMenuToggle>
    );
}

export default BtnMenuToggle;