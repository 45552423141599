import * as actions from '../actions/modalActions';
import { ModalActionTypes } from '../../types';
import { ModalHintStateInterface } from '../../interfaces';


const initialState = {
    open: false,
    poi: null
}

export function modalReducer(state: ModalHintStateInterface = initialState, action: ModalActionTypes) {
    switch (action.type) {
        case actions.MODAL_UPDATE:
            return {
                ...state, 
                open: true,
                poi: action.payload.poi
            }
        case actions.MODAL_CLOSE:
            return {
                ...state,
                open: false
            }
        default:
            return state
    }
}