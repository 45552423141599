import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import BtnRoundWithIcon from '../components/BtnRoundWithIcon';
import Style from './TimerPage.module.scss';
import { connect } from "react-redux";
import { timerStartAction, timerStopAction } from '../store/actions/timerActions';
import { GlobalStateInterface, ParkourInterface, TimerStateInterface } from '../interfaces';
import { parkoursSelectedParkourSelector } from '../store/selectors';
import Header from '../components/Header';

type PropsType = {
    // props
    timer: TimerStateInterface,
    parkour: ParkourInterface|null,
    // actions
    timerStartAction: () => void,
    timerStopAction: () => void
}

const TimerPage: React.FC<PropsType> = (props) => {

    if(props.parkour === null){
        return <div>Loading</div>
    }

    const handleStart = () => {
        props.timerStartAction();
    }

    const handlePause = () => {
        props.timerStopAction();
    }

    const getSeconds = `0${(props.timer.time % 60)}`.slice(-2)
    const minutes = `${Math.floor(props.timer.time / 60)}`
    const getMinutes = `0${parseInt(minutes) % 60}`.slice(-2)
    const getHours = `0${Math.floor(props.timer.time / 3600)}`.slice(-2)

    return (
        <IonPage>
            <Header parkour={props.parkour} />
            <IonContent>
                <div className={Style.container} style={{
                    backgroundColor: props.parkour.color1,
                    borderBottom: `10px solid ${props.parkour.bgColor}`,
                }}>
                    <h1 className={Style.appTitle}>{props.parkour.name}</h1>
                    <button style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }} className={Style.timerStartStopButton} onClick={props.timer.onGoing ? handlePause : handleStart}>
                        <img src={props.timer.onGoing ? 'assets/icon-btn-pause.png' : 'assets/icon-btn-play.png'} className={Style.btnGoStop} alt="button icon" />
                    </button>
                    <div className={Style.timeCdContainer}>
                        <p style={{ color: props.parkour.color1 }}>
                            {getHours + ' : ' + getMinutes + ' : ' + getSeconds}
                        </p>
                    </div>
                    <div className={Style.timerButtonsRow}>
                        <BtnRoundWithIcon type='indices' clickable={props.timer.onGoing} />
                        <BtnRoundWithIcon type='plan' clickable={props.timer.onGoing} />
                        <BtnRoundWithIcon type='validation' clickable={props.timer.onGoing} />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );

};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    timer: state.timer,
    parkour: parkoursSelectedParkourSelector(state),
});

const mapDispatchToProps = {
    timerStartAction,
    timerStopAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TimerPage);