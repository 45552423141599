import React from 'react';

type PropsType = {
    discovered?:boolean,
}

const PoiIcon: React.FC<PropsType> = (props) => {
    return (
        <img src={props.discovered ? "assets/discovered-poi-icon-number.png" : "assets/poi-icon-number.png"} alt=""/>
    );
}

export default PoiIcon;