import React from 'react';
import Style from './ModalHint.module.scss';
import { connect } from 'react-redux';
import { modalCloseAction } from '../store/actions/modalActions';
import { ParkourInterface, ModalHintStateInterface, GlobalStateInterface } from '../interfaces';
import { parkoursSelectedParkourSelector } from '../store/selectors';

type PropsType = {
    // props
    modalHint: ModalHintStateInterface,
    parkour: ParkourInterface | null,
    // actions
    modalCloseAction: () => void
}

const ModalHint: React.FC<PropsType> = (props) => {

    return (
        <div className={Style.container} style={{ display: props.modalHint.open ? "flex" : "none" }}>
            <div className={Style.modalContainer} style={{
                borderLeft: `8px solid ${props.parkour !== null ? props.parkour.color1 : "#ffffff"}`,
            }}>
                <div className={Style.hintIconContainer}>
                    <img src="assets/discovered-poi-icon-number.png" alt="astuce icon" className={Style.hintIcon} />
                </div>
                <div className={Style.modalText}>
                    <p style={{ fontWeight: "bold", fontSize: 15 }}>Indice n°{props.modalHint.poi?.position}</p>
                    <p>{props.modalHint.poi?.hint}</p>
                </div>
                <img src="assets/close-sign.png" alt="bouton icon fermer" height="14" width="14" onClick={() => props.modalCloseAction()
                } />
            </div>
        </div>
    );
};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    parkour: parkoursSelectedParkourSelector(state),
    modalHint: state.modalHint,
});

const mapDispatchToProps = {
    modalCloseAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalHint);