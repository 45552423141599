import { TimerStateInterface } from '../../interfaces';
import { TimerActionTypes } from '../../types';
import * as actions from '../actions/timerActions';

const initialState = { onGoing: false, time: 0 }

export function timerReducer(state:TimerStateInterface = initialState, action:TimerActionTypes) {
    switch (action.type) {
        case actions.TIMER_START:
            return {
                ...state, onGoing: true
            }
        case actions.TIMER_STOP:
            return {
                ...state, onGoing: false
            }
        case actions.TIMER_TICK:
            return {
                ...state, time: state.time + 1
            }
        case actions.TIMER_RESET:
            return {
                ...state, onGoing: false, time: 0
            }
    default:
    return state
  }
}
