import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { connect } from "react-redux";
import { ParkourInterface, GlobalStateInterface, PointOfInterestInterface } from '../interfaces';
import Style from './HintPage.module.scss';
import PoiIcon from '../components/PoiIcon';
import Header from '../components/Header';
import { parkourspoiDiscoveredSelector, parkoursSelectedParkourSelector } from '../store/selectors';

type PropsType = {
    parkour: ParkourInterface | null,
    poiDiscovered: number[],
}

const HintPage: React.FC<PropsType> = (props) => {

    if (props.parkour === null) {
        return <div>Loading</div>
    }

    return (
        <IonPage className={Style.container} style={{
            borderBottom: `8px solid ${props.parkour.bgColor}`
        }}>
            <Header parkour={props.parkour} />
            <IonContent>
                <h1 style={{
                    backgroundColor: props.parkour.color1,
                }}>
                    <span style={{ color: props.parkour.bgColor, fontWeight: 550 }}> Liste des indices</span>
                </h1>
                <div className={Style.hintOverview}>
                    {props.parkour.pointOfInterests.map((poi: PointOfInterestInterface, index: number) => (
                        <div className={Style.hintOverviewItem} key={poi.id}>
                            <PoiIcon discovered={props.poiDiscovered.includes(index)} />
                            <p
                                style={{ color: props.parkour!.bgColor, fontWeight: 550 }}
                                className={Style.hintNumber + (poi.position >= 10 ? ' tenPlus' : '')}>
                                {poi.position}
                            </p>
                        </div>
                    ))}
                </div>
                <div className={Style.hintList}>
                    {props.parkour.pointOfInterests.map((poi: PointOfInterestInterface, index: number) => (
                        <div
                            style={{ borderLeft: `6px solid ${props.parkour!.color1}` }}
                            className={Style.hintListItem + (props.poiDiscovered.includes(index) ? ' discovered' : '')}
                            key={index}>
                            <div className={Style.imgContainer}>
                                <PoiIcon discovered={props.poiDiscovered.includes(index)} />
                                <p
                                    style={{ color: props.parkour!.bgColor, fontWeight: 550 }}
                                    className={Style.hintNumber + (poi.position >= 10 ? ' tenPlus' : '')}>
                                    {poi.position}
                                </p>
                            </div>
                            <p className={Style.hintText}>{props.poiDiscovered.includes(index) ? poi.hint : "À découvrir"}</p>
                        </div>
                    ))}
                </div>
            </IonContent>
        </IonPage>
    );
};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    parkour: parkoursSelectedParkourSelector(state),
    poiDiscovered: parkourspoiDiscoveredSelector(state),
});

export default connect(mapStateToProps)(HintPage);
