import { ParkourInterface } from '../../interfaces';
import { Dispatch } from 'redux';
import {
    AddPoiDiscoveredAction, FetchParkourErrorAction, FetchParkourSuccessAction, ResetPoiDiscoveredAction, SetSelectedParkourAction, DoRequestAction, FetchOfflineSuccessAction, FetchOfflineErrorAction, UpdatePercentAction, AbortLoadingAction
} from '../../interfaces/actions.interface';
import { findOne, findForHost } from '../../conf/api.parkour';

export const ADD_POI_DISCOVERED = 'add_poi_discovered';
export const RESET_POI_DISCOVERED = 'reset_poi_discovered';

export const REQUEST_PARKOUR = 'request_parkour';
export const FETCH_PARKOUR = 'fetch_parkour';
export const FETCH_PARKOUR_SUCCESS = 'fetch_parkour_success';
export const FETCH_PARKOUR_ERROR = 'fetch_parkour_error';
export const SET_SELECTED_PARKOUR = 'set_selected_parkour';
export const FETCH_SINGLE_PARKOUR_SUCCESS = 'fetch_single_parkour_success';

export const DO_REQUEST = 'do_request';
export const FETCH_OFFLINE_SUCCESS = 'fetch_offline_success';
export const FETCH_OFFLINE_ERROR = 'fetch_offline_error';
export const UPDATE_PERCENT = 'update_percent';
export const ABORT_LOADING = 'abort_loading';

export const doRequestAction = (): DoRequestAction => {
    return {
        type: DO_REQUEST,
    };
};

export const fetchOfflineSuccessAction = (): FetchOfflineSuccessAction => {
    return {
        type: FETCH_OFFLINE_SUCCESS,
    };
};

export const fetchOfflineErrorAction = (error: any): FetchOfflineErrorAction => {
    return {
        type: FETCH_OFFLINE_ERROR,
        payload: error,
    };
};

export const updatePercentAction = (percents: number | null): UpdatePercentAction => {
    return {
        type: UPDATE_PERCENT,
        payload: percents
    };
};

export const abortLoadingAction = (): AbortLoadingAction => {
    return {
        type: ABORT_LOADING,
    }
}

export const fetchOfflineAction = (parkour: ParkourInterface | null) => {
    return (dispatch: Dispatch) => {
        dispatch(doRequestAction());
        if (navigator.serviceWorker.controller !== null) {
            navigator.serviceWorker.controller.postMessage({
                type: 'dataload',
                parkour: parkour,
            });
            const channel = new BroadcastChannel('sw-messages');
            channel.addEventListener('message', event => {
                if (event.data.msg === 'success') {
                    dispatch(fetchOfflineSuccessAction())
                } else if (event.data.msg === 'updatePercent') {
                    dispatch(updatePercentAction(event.data.percents))
                }
                else if (event.data.msg === 'error') {
                    dispatch(fetchOfflineErrorAction(event.data.err))
                }
            }, { once: false });
        }
    };
};

export const fetchParkourSuccessAction = (
    parkours: ParkourInterface[]
): FetchParkourSuccessAction => {
    return {
        type: FETCH_PARKOUR_SUCCESS,
        payload: parkours,
    };
};

export const fetchParkourErrorAction = (error: any): FetchParkourErrorAction => {
    return {
        type: FETCH_PARKOUR_ERROR,
        payload: error,
    };
};

export const fetchParkourAction = () => {
    return async (dispatch: Dispatch) => {
        dispatch(doRequestAction());
        try {
            const response = await findForHost();
            dispatch(fetchParkourSuccessAction(response.data));
        } catch (e) {
            dispatch(fetchParkourErrorAction(e));
        }
    };
};

export const setSelectedParkourAction = (index: number): SetSelectedParkourAction => {
    return {
        type: SET_SELECTED_PARKOUR,
        payload: index
    };
}

export const addPoiDiscoveredAction = (id: number): AddPoiDiscoveredAction => {
    return {
        type: ADD_POI_DISCOVERED,
        payload: id,
    }
}

export const resetPoiDiscoveredAction = (): ResetPoiDiscoveredAction => {
    return {
        type: RESET_POI_DISCOVERED,
    }
}

export const updateSelectParkourAction = (code: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(doRequestAction());

        try {
            const response = await findOne(code);
            dispatch(fetchSingleParkourSuccessAction(response.data));
        } catch (e) {
            dispatch(fetchParkourErrorAction(e));
        }
    };
};

export const fetchSingleParkourSuccessAction = (
    parkour: ParkourInterface
) => {
    return {
        type: FETCH_SINGLE_PARKOUR_SUCCESS,
        payload: parkour,
    };
};