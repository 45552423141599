import {
    TimerResetActionInterface,
    TimerStartActionInterface,
    TimerStopActionInterface,
    TimerTickActionInterface
} from "../../interfaces/actions.interface";

export const TIMER_START = 'timer_start';
export const TIMER_STOP = 'timer_stop';
export const TIMER_TICK = 'timer_tick';
export const TIMER_RESET = 'timer_reset';

export const timerStartAction = (): TimerStartActionInterface => {
    return {
        type: TIMER_START,
    };
}

export const timerStopAction = (): TimerStopActionInterface => {
    return {
        type: TIMER_STOP,
    };
}

export const timerTickAction = (): TimerTickActionInterface => {
    return {
        type: TIMER_TICK,
    };
}

export const timerResetAction = (): TimerResetActionInterface => {
    return {
        type: TIMER_RESET,
    };
}