import { PointOfInterestInterface } from "../../interfaces";
import { ModalCloseActionInterface, ModalUpdateActionInterface } from "../../interfaces/actions.interface";

export const MODAL_UPDATE = 'modal_update';
export const MODAL_CLOSE = 'modal_close';

export const modalUpdateAction = (poi:PointOfInterestInterface|null): ModalUpdateActionInterface => {
    return {
        type: MODAL_UPDATE,
        payload: {
            poi: poi
        },
    };
}

export const modalCloseAction = (): ModalCloseActionInterface => {
    return {
        type: MODAL_CLOSE
    };
}