import {
    IonContent,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
} from '@ionic/react';
import BtnMenuToggle from './BtnMenuToggle';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Style from './MenuEns.module.scss';
import { timerResetAction,resetPoiDiscoveredAction } from '../store/actions';
import { GlobalStateInterface, ParkourInterface, TimerStateInterface } from '../interfaces';
import { hexToRgb } from '../utils/functions';
import { parkoursSelectedParkourSelector } from '../store/selectors';

type PropsType = {
    // props
    parkour: ParkourInterface | null,
    timer: TimerStateInterface,
    // actions
    timerResetAction: () => void,
    resetPoiDiscoveredAction: () => void,
}


const MenuEns: React.FC<PropsType> = (props) => {
    const [modalOpen, setModalOpen] = useState(false);

    const combinedFunctions = () => {
        props.timerResetAction();
        props.resetPoiDiscoveredAction();
        setModalOpen(false)
    }

    const getSeconds = `0${(props.timer.time % 60)}`.slice(-2)
    const minutes = `${Math.floor(props.timer.time / 60)}`
    const getMinutes = `0${parseInt(minutes) % 60}`.slice(-2)
    const getHours = `0${Math.floor(props.timer.time / 3600)}`.slice(-2)

    const bgRgb = props.parkour ? hexToRgb(props.parkour.bgColor) : null;

    return (
        <IonMenu contentId="main" type="overlay">
            { modalOpen &&
                <div className={Style.modalContainer} style={{
                    backgroundColor: props.parkour ? props.parkour.bgColor : "whitesmoke",
                    border: `4px solid ${props.parkour ? props.parkour.color1 : "lightgrey"}`
                }}>
                    <p className={Style.confirmResetText} >Etes-vous sûr ?</p>
                    <div className={Style.modalBtnContainer}>
                        <IonMenuToggle className={Style.modalBtnOuiToggle}>
                            <Link className={Style.modalBtnOuiLink} to="/">
                                <div className={Style.modalBtnOui} onClick={() => combinedFunctions()}>
                                    Oui
                                </div>
                            </Link>
                        </IonMenuToggle>
                        <div className={Style.modalBtnNon} onClick={() => setModalOpen(false)}>
                            Non
                        </div>
                    </div>
                </div>
            }
            <IonContent>
                <IonList id="inbox-list" className={Style.menuContainer} style={{
                    backgroundColor: bgRgb ? `rgba(${bgRgb.r}, ${bgRgb.g}, ${bgRgb.b}, 0.9)` : "blue"
                }}
                >
                    <IonListHeader className={Style.menuHeaderTitle}>Menu</IonListHeader>
                    <div className={Style.menuTimeCdContainer}>
                        <p className={Style.timeCd} style={{ color: props.parkour ? props.parkour.color1 : "grey" }}>
                            {getHours + ' : ' + getMinutes + ' : ' + getSeconds}
                        </p>
                    </div>
                    <BtnMenuToggle type='timer' />
                    <BtnMenuToggle type='indices' />
                    <BtnMenuToggle type='plan' />
                    <BtnMenuToggle type='validation' />
                    <div className={Style.menuItemSpecial} onClick={() => setModalOpen(true)}>
                        <div className={Style.menuResetButton}>
                            <p>Réinitialiser</p>
                            <p>Recommencer l'enquête</p>
                        </div>
                    </div>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

const mapStateToProps = (state: GlobalStateInterface) => ({
    timer: state.timer,
    parkour: parkoursSelectedParkourSelector(state),
});

const mapDispatchToProps = {
    timerResetAction,
    resetPoiDiscoveredAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuEns);