import { IonContent, IonPage } from '@ionic/react';
import React from 'react';


const NotFoundPage =  () => (
    <IonPage>
        <IonContent>
            <p className="ion-padding ion-text-center">Page introuvable</p>
        </IonContent>
    </IonPage>

)

export default NotFoundPage;