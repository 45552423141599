import axios from 'axios';

const apiParkour = axios.create({
    baseURL: process.env.REACT_APP_ENS_URL + "/api",
});

export function findForHost() {
    const host = window.location.hostname.replace('www.', '');
    return apiParkour.get(`/parkours?page=1&pagination=false&enabled=true&client.host=${host}`);
}

export function findOne(code: string) {
    return apiParkour.get(`/parkours/${code}`);
}

export default apiParkour;