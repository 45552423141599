import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { ParkourInterface } from '../interfaces';

type PropsType = {
    parkour: ParkourInterface,
}

const Header: React.FC<PropsType> = (props) => {
    return (
        <IonHeader>
            <IonToolbar color="unset" style={{ background: props.parkour.bgColor }}>
                <IonButtons slot="start">
                    <IonMenuButton style={{ color: "white" }} />
                </IonButtons>
                <IonTitle style={{ color: "white" }}>{props.parkour.name}</IonTitle>
            </IonToolbar>
        </IonHeader>
    );
}

export default Header;