import React, { useEffect, useState } from 'react';
import { IonButton, IonContent, IonIcon, IonItem, IonList, IonPage, IonToast, useIonPopover, IonProgressBar, IonText } from '@ionic/react';
import { Link } from 'react-router-dom';
import Style from './HomePage.module.scss';
import { connect, useDispatch } from "react-redux";
import { ParkourInterface, GlobalStateInterface } from '../interfaces';
import { Redirect } from 'react-router-dom';
import { parkoursErrorSelector, parkoursIsLoadingSelector, parkoursIsLocallyLoadedSelector, parkoursPercentSelector, parkoursSelectedParkourSelector } from '../store/selectors';
import { optionsOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import ObjectImg from '../components/ObjectImg';
import { updateSelectParkourAction, fetchOfflineAction, updatePercentAction, abortLoadingAction } from '../store/actions'
import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/react';

const { Network } = Plugins;

type PropsType = {
    parkour: ParkourInterface | null,
    error: any,
    isLoading: boolean,
    isLocallyLoaded: boolean,
    progressPercents: number | null,
}

const PopoverList: React.FC<{
    onHide: () => void;
    history: any;
    parkour: ParkourInterface | null;
    isLocallyLoaded: boolean
    downloadUpdate: () => void;
    downloadOfflineData: () => void;
}> = ({ onHide, history, downloadUpdate, downloadOfflineData, parkour, isLocallyLoaded }) => {
    return (
        <IonList>
            <IonItem button onClick={() => {
                onHide();
                history.push('/parkour');
            }}>
                Changer d'enquête
            </IonItem>
            <IonItem button onClick={downloadUpdate}>
                Mettre à jour l'enquête
            </IonItem>
            {parkour && parkour.offline && !isLocallyLoaded && !isPlatform('ios') &&
                <IonItem button onClick={downloadOfflineData}>
                    Télécharger les données
                </IonItem>
            }
            <IonItem lines="none" detail={false} button onClick={onHide}>
                Fermer
            </IonItem>
        </IonList>
    );
}

const HomePage: React.FC<PropsType> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [toast, setToast] = useState({ open: false, message: "" });
    const [networkStatus, setNetworkStatus] = useState(false);

    const downloadUpdate = (dismiss: () => void) => {
        if (!props.parkour) {
            dismiss();
            return;
        }
        if (!networkStatus) {
            setToast({ open: true, message: "Aucune connexion internet" });
            dismiss();
            return;
        }

        dispatch(updateSelectParkourAction(props.parkour.code));
        dismiss();
    }

    const downloadOfflineData = (dismiss: () => void) => {
        dispatch(fetchOfflineAction(props.parkour));
        dismiss();
        return;
    }

    const [present, dismiss] = useIonPopover(PopoverList, {
        onHide: () => dismiss(),
        history: history,
        downloadUpdate: () => downloadUpdate(dismiss),
        downloadOfflineData: () => downloadOfflineData(dismiss),
        parkour: props.parkour,
        isLocallyLoaded: props.isLocallyLoaded
    });

    useEffect(() => {
        Network.addListener('networkStatusChange', (status) => {
            setNetworkStatus(status.connected);
        });

        async function init() {
            let status = await Network.getStatus();
            setNetworkStatus(status.connected);
        }

        init();
    }, [networkStatus])

    if (props.parkour === null) {
        return <Redirect to="/parkour" />
    }

    return (
        <IonPage>
            <IonContent>
                {props.isLoading &&
                    <div className={Style.loadingContainer}>
                        <IonText className={Style.loadingText}>Chargement</IonText>
                        <IonProgressBar value={props.progressPercents !== null ? props.progressPercents : 0} className={Style.loadingBar}></IonProgressBar>

                        <IonButton className={Style.cancelBtn} onClick={(e) => {
                            // reset progressPercents
                            e.preventDefault();
                            dispatch(updatePercentAction(null));
                            dispatch(abortLoadingAction());
                            // cancel function (mais je pense que c'est impossible)
                        }}>Annuler</IonButton>
                    </div>
                }
                <div style={{ display: props.isLoading ? 'none' : 'block' }}>
                    <IonButton
                        style={{ position: 'absolute', top: '5px', right: '5px', color: 'white' }}
                        fill="clear"
                        expand="block"
                        onClick={(e) =>
                            present({
                                event: e.nativeEvent,
                            })
                        }>
                        <IonIcon icon={optionsOutline}></IonIcon>
                    </IonButton>
                    <div className={Style.container} style={{
                        backgroundColor: props.parkour.bgColor,
                        borderBottom: `8px solid ${props.parkour.color1}`
                    }}>
                        <ObjectImg className={Style.logo1} image={props.parkour.logo1} />
                        <h1 className={Style.appTitle}>{props.parkour.name}</h1>
                        <p className={Style.homeDesc}>{props.parkour.description}</p>
                        <Link to='/timer' className={Style.homeGoButton} style={{ backgroundColor: props.parkour.color2, color: props.parkour.bgColor }}>
                            GO
                        </Link>
                        <ObjectImg className={Style.logo2} image={props.parkour.logo2} />
                    </div>
                </div>

                <IonToast
                    isOpen={toast.open}
                    message={toast.message}
                    onDidDismiss={() => setToast({ open: false, message: "" })}
                    duration={800}
                />
                {props.error &&
                    <IonToast isOpen={true} message={"Une erreur est survenue, veuillez vérifier votre connexion"} duration={800} />
                }
            </IonContent>
        </IonPage>
    );
};


export default connect((state: GlobalStateInterface) => ({
    isLocallyLoaded: parkoursIsLocallyLoadedSelector(state),
    progressPercents: parkoursPercentSelector(state),
    isLoading: parkoursIsLoadingSelector(state),
    parkour: parkoursSelectedParkourSelector(state),
    error: parkoursErrorSelector(state)
}))(HomePage);