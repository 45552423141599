import React, { useRef, useState } from 'react';
import { IonContent, IonPage, IonInput } from '@ionic/react';
import { Link } from 'react-router-dom';
import Style from './ValidationPage.module.scss';
import { connect } from "react-redux";
import { timerStopAction } from '../store/actions/timerActions';
import { GlobalStateInterface, ParkourInterface, TimerStateInterface } from '../interfaces';
import Header from '../components/Header';
import { parkoursSelectedParkourSelector } from '../store/selectors';

type PropsType = {
    // props
    timer: TimerStateInterface,
    parkour: ParkourInterface|null,
    // actions
    timerStopAction: () => void
}

const ValidationPage: React.FC<PropsType> = (props) => {

    const [code, setCode] = useState<string | null>(null);
    const [showError, setShowError] = useState(false);

    const inputRefs = [
        useRef<HTMLIonInputElement>(null),
        useRef<HTMLIonInputElement>(null),
        useRef<HTMLIonInputElement>(null),
    ];

    const combinedFunctions = () => {
        if (props.parkour!.answer === code) {
            if (props.timer.onGoing) props.timerStopAction();
            if (showError) setShowError(false);
        }
        else {
            setShowError(true)
        }
    }

    // const ffStopTimer = () => {
    //     if (props.timer.onGoing) props.timerStopAction();
    //     if (showError) setShowError(false);
    // }

    const updateCode = (index: number) => {
        if (!inputRefs[index].current?.value) {
            return;
        }

        //if next input is defined: autofocus
        if (inputRefs[(index + 1)] !== undefined) {
            inputRefs[(index + 1)].current?.setFocus();
        } else {
            inputRefs[index].current?.setBlur();
        }

        let code = '';
        inputRefs.forEach((ref) => {
            code += ref.current?.value;
        });

        setCode(code);
    }

    if(props.parkour === null){
        return <div>Loading</div>
    }

    return (
        <IonPage style={{
            borderBottom: `8px solid ${props.parkour.bgColor}`
        }}>
            <Header parkour={props.parkour} />
            <IonContent>
                <div className={Style.container}>
                    <img className={Style.trophyIcon} src="assets/validation-yellow-trophy-icon.png" alt="icone de trophée" />
                    <p className={Style.underIconText}>
                        Saisissez le code <span className={Style.bold}>découvert</span> et découvrez si vous avez <span className={Style.bold}>gagné</span>
                    </p>
                    <div className={Style.inputValidationContainer}>
                        {[0, 1, 2].map((index) => (
                            <IonInput
                                key={'input-' + index}
                                type="number"
                                min="0"
                                max="9"
                                ref={inputRefs[index]}
                                placeholder="?"
                                onIonChange={(e) => updateCode(index)}
                                onFocus={e => inputRefs[index].current!.value = ""}
                                className={Style.inputCode}
                                style={{ backgroundColor: props.parkour!.color1 }}
                            >
                            </IonInput>
                        ))}
                    </div>
                    <Link to={code === props.parkour.answer ? '/success' : '#'} className={Style.validerBtn}>
                        <button className={Style.textValiderBtn} onClick={() => combinedFunctions()}>VALIDER</button>
                    </Link>

                    {showError &&
                        <>
                            <p className={Style.errorMessage}>{props.parkour.failMessage}</p>
                        </>
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    timer: state.timer,
    parkour: parkoursSelectedParkourSelector(state),
});

const mapDispatchToProps = {
    timerStopAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationPage);
