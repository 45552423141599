import { FeatureInterface } from "../interfaces";

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : {
              r: 255,
              g: 255,
              b: 255,
          };
}

export const featureStyle = (f: FeatureInterface) => {
    if (f.geometry.style !== undefined) {
        return f.geometry.style;
    }

    switch (f.geometry.type) {
        case "LineString":
            return lineStringStyle(f);

        case "Polygon":
            return polygonStyle(f);
    }

    return {};
};

const polygonStyle = (f: FeatureInterface) => {
    if (f.properties.crop === "grape") {
        return {
            fillColor: "#aedfa3",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.amenity === "parking") {
        return {
            fillColor: "#eeeeee",
            color: "#eeeeee",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "forest") {
        return {
            fillColor: "#add19e",
            color: "#add19e",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "quarry") {
        return {
            fillColor: "#c5c3c3",
            color: "#b2b7ae",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "grass") {
        return {
            fillColor: "#cdebb0",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "allotments") {
        return {
            fillColor: "#c9e1bf",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.leisure === "park") {
        return {
            fillColor: "#c8facc",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.leisure === "swimming_pool") {
        return {
            fillColor: "#aad3df",
            color: "#7dcada",
            fillOpacity: "1",
        };
    }
    if (f.properties.building === "yes") {
        return {
            fillColor: "#d9d0c9",
            color: "#c0beaa",
            fillOpacity: "1",
            weight: 1,
        };
    }
    if (f.properties.natural === "water") {
        return {
            fillColor: "#aad3df",
            color: "#aad3df",
            fillOpacity: "1",
        };
    }
    if (f.properties.natural === "scrub") {
        return {
            fillColor: "#c8d7ab",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "meadow") {
        return {
            fillColor: "#cdebb0",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "orchard") {
        return {
            fillColor: "#aedfa3",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "vineyard") {
        return {
            fillColor: "#aedfa3",
            color: "unset",
            fillOpacity: "1",
        };
    }
    if (f.properties.landuse === "farmland") {
        return {
            fillColor: "#eef0d5",
            color: "unset",
            fillOpacity: "1",
        };
    }

    if (f.properties.landuse === "bare_rock") {
        return {
            fillColor: "#ded9d3",
            color: "unset",
            fillOpacity: "1",
        };
    }
    return {
        color: "unset",
        fillOpacity: 0,
    };
};

const lineStringStyle = (f: FeatureInterface) => {
    if (f.properties.highway === "footway") {
        return {
            weight: 1,
            color: "#f3a08f",
            dashArray: "4",
        };
    } else if (f.properties.natural === "tree_row") {
        return {
            weight: 8,
            color: "#aacea3",
        };
    } else if (f.properties.type === "associatedStreet") {
        return {
            weight: 8,
            color: "#f7fabf",
        };
    } else if (f.properties.service === "parking_aisle") {
        return {
            weight: 3,
            color: "white",
        };
    } else if (f.properties.highway === "service") {
        if (f.properties.service === "driveway") {
            return {
                weight: 2,
                color: "white",
            };
        }
        return {
            weight: 7,
            color: "white",
        };
    } else if (f.properties.highway === "secondary") {
        return {
            weight: 7,
            color: "white",
        };
    } else if (f.properties.highway === "unclassified") {
        return {
            weight: 7,
            color: "white",
        };
    } else if (f.properties.natural === "cliff") {
        return {
            weight: 7,
            color: "lightgrey",
        };
    } else if (f.properties.highway === "path") {
        return {
            weight: 2,
            fillColor: "white",
            color: "#f58c7c",
            dashArray: "4",
            fillOpacity: "1",
        };
    } else if (f.properties.type === "waterway") {
        return {
            weight: 8,
            fillColor: "#aad3df",
            color: "#aad3df",
            fillOpacity: "1",
        };
    } else if (f.properties.waterway === "stream") {
        return {
            weight: 8,
            fillColor: "#aad3df",
            color: "#aad3df",
            fillOpacity: "1",
        };
    } else if (f.properties.waterway === "drain") {
        return {
            weight: 4,
            dashOffset: 4,
            dashArray: "8",
            fillColor: "#aad3df",
            color: "#aad3df",
            fillOpacity: "1",
        };
    } else if (f.properties.highway === "track") {
        if (f.properties.tracktype === "grade2") {
            return {
                color: "#ac8331",
                weight: 2,
                dashArray: "12",
                dashOffset: "1",
            };
        }
        if (f.properties.tracktype === "grade1") {
            return {
                color: "#a48028",
                weight: 2,
            };
        }
        return {
            color: "#ac8331",
            weight: 1,
            dashArray: "8",
            dashOffset: "1",
        };
    }

    return {
        weight: 10,
        color: "unset",
    };
};
