import React from 'react';
import { Route } from 'react-router-dom';
import {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
} from '@ionic/react';
import { store, persistor } from './store/configureStore';
import { Provider } from 'react-redux';
import { IonReactRouter } from '@ionic/react-router';
import MapPage from './pages/MapPage';
import HomePage from './pages/HomePage';
import TimerPage from './pages/TimerPage';
import HintPage from './pages/HintPage';
import ValidationPage from './pages/ValidationPage';
import SuccessPage from './pages/SuccessPage';
import { PersistGate } from 'redux-persist/integration/react';
import MenuEns from './components/MenuEns';
import Timer from './components/Timer';
import ParkourListPage from './pages/ParkourListPage';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import NotFoundPage from './pages/NotFoundPage';

const App: React.FC = () => (

    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <IonApp>
                <Timer />
                <IonReactRouter>
                    <IonSplitPane contentId="main" when="true">
                        <MenuEns />
                        <IonRouterOutlet id="main">
                            <Route path="/" component={HomePage} exact={true} />
                            <Route path="/parkour" component={ParkourListPage} exact={true} />
                            <Route path="/plan" component={MapPage} exact={true} />
                            <Route path="/timer" component={TimerPage} exact={true} />
                            <Route path="/hint" component={HintPage} exact={true} />
                            <Route path="/validation" component={ValidationPage} exact={true} />
                            <Route path="/success" component={SuccessPage} />
                            <Route path="/not-found" component={NotFoundPage} />
                            {/* TODO: redirect auto to not found like 404 */}
                        </IonRouterOutlet>
                    </IonSplitPane>
                </IonReactRouter>
            </IonApp>
        </PersistGate>
    </Provider>
);

export default App;
