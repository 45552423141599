import React, { useState } from 'react';
import { GeoJsonObject } from 'geojson';
import { GeoJSON, useMapEvents } from 'react-leaflet';
import { featureStyle } from '../utils/functions';
import { FeatureInterface } from '../interfaces';

type PropsType = {
    feature: FeatureInterface,
    initialZoom: number
}

const MapFeature: React.FC<PropsType> = ({ initialZoom, feature }) => {
    const [zoomLevel, setZoomLevel] = useState(initialZoom);

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    const style = featureStyle(feature);

    const ignoreFeature = (f: FeatureInterface) => {
        if (f.geometry.type === "Point") {
            return true;
        }
        if (f.geometry.type === "MultiLineString") {
            return true;
        }

        if (f.properties.zoomRange !== undefined && (
            (f.properties.zoomRange.max && zoomLevel > f.properties.zoomRange.max) ||
            (f.properties.zoomRange.min && zoomLevel < f.properties.zoomRange.min))
        ) {
            return true;
        }

        return false;
    }

    if (ignoreFeature(feature)) {
        return <></>;
    }

    let k = 1;
    if (style.weight !== undefined) {
        k = (zoomLevel / 30);
        style.weight *= k;
    }
    //TODO: check element to/display hide at zoomLevel

    return (
        <GeoJSON key={feature.properties.id + k} data={feature as GeoJsonObject} style={style}></GeoJSON>
    )
}

export default MapFeature;