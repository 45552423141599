import { ParkourInterface, ParkoursStateInterface } from '../../interfaces';
import { ParkourActionTypes } from '../../types';
import * as actions from '../actions/parkourActions';

const initialState = {
    data: [],
    loading: true,
    error: null,
    selectedParkour: null,
    poiDiscovered: [],
    locallyLoaded: false,
    progressPercents: null,
};

export function parkoursReducer(state: ParkoursStateInterface = initialState, action: ParkourActionTypes) {

    switch (action.type) {

        case actions.DO_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case actions.FETCH_PARKOUR_SUCCESS:
            if (action.payload) {
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: null,
                };
            }
            return {
                ...state,
                loading: false,
            };


        case actions.FETCH_PARKOUR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case actions.SET_SELECTED_PARKOUR:
            return {
                ...state,
                selectedParkour: action.payload,
                locallyLoaded: false,
                poiDiscovered: [],
            }

        case actions.ADD_POI_DISCOVERED:

            if (!state.poiDiscovered.includes(action.payload)) {
                const poiDiscovered = [...state.poiDiscovered];
                poiDiscovered.push(action.payload);
                return {
                    ...state,
                    poiDiscovered: poiDiscovered,
                }
            }
            return state;


        case actions.RESET_POI_DISCOVERED:
            return {
                ...state,
                poiDiscovered: [],
            }

        case actions.FETCH_SINGLE_PARKOUR_SUCCESS:
            return {
                ...state,
                loading: false,
                locallyLoaded: false,
                error: null,
                data: state.data.map((p: ParkourInterface) => (p.code === action.payload.code ? action.payload : p)),
                poiDiscovered: [],
            }

        case actions.FETCH_OFFLINE_SUCCESS:
            return {
                ...state,
                loading: false,
                locallyLoaded: true,
                error: null,
            };


        case actions.FETCH_OFFLINE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case actions.ABORT_LOADING:
            return {
                ...state,
                loading: false,
            }

        case actions.UPDATE_PERCENT:
            return {
                ...state,
                progressPercents: action.payload,
            };

        default:
            return state;

    }
}

