import { createStore, applyMiddleware, Middleware, Store} from 'redux';
import { combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { parkoursReducer,modalReducer,timerReducer } from './reducers';

const middlewares:Middleware[] = [thunkMiddleware]; //create middleware to let use async function in reducers

//create persisted store (localStoage for web)
const persistedReducer = persistReducer({
    key: 'root',
    storage,
}, combineReducers({
    parkours: parkoursReducer,
    modalHint: modalReducer,
    timer: timerReducer,
}));

//create custom store = base store + key with reducers with async function
const store:Store = createStore(persistedReducer, applyMiddleware(...middlewares)) as Store;

let persistor = persistStore(store);

export {store,persistor};