import React, { useState, useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import Style from './MapPage.module.scss';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import MapMarkers from '../components/MapMarkers';
import { connect } from "react-redux";
import { ParkourInterface, GlobalStateInterface } from '../interfaces';
import Header from '../components/Header';
import { parkoursSelectedParkourSelector } from '../store/selectors';
import MapFeature from '../components/MapFeature';
import { isPlatform } from '@ionic/core';
import { Plugins } from '@capacitor/core';

const { Network } = Plugins;

type PropsType = {
    parkour: ParkourInterface | null,
}

const InvalidSize = () => {
    const map = useMap();
    setTimeout(() => {
        map.invalidateSize();
    }, 500);
    return null;
}

const MapPage: React.FC<PropsType> = (props) => {
    const [networkStatus, setNetworkStatus] = useState(false);

    useEffect(() => {
        Network.addListener('networkStatusChange', (status) => {
            setNetworkStatus(status.connected);
        });

        async function init() {
            let status = await Network.getStatus();
            setNetworkStatus(status.connected);
        }

        init();
    }, [networkStatus])


    if (props.parkour === null) {
        return <div>Loading</div>
    }

    const config = props.parkour.mapConfig;

    let centerLat = 0;
    let centerLng = 0;
    if (config.center.latitude && config.center.longitude) {
        centerLat = config.center.latitude;
        centerLng = config.center.longitude;
    } else if (config.bounds.minLatitude && config.bounds.maxLatitude && config.bounds.minLongitude && config.bounds.maxLongitude) {
        centerLat = (config.bounds.minLatitude + config.bounds.maxLatitude) / 2;
        centerLng = (config.bounds.minLongitude + config.bounds.maxLongitude) / 2;
    }

    let uniqConfigKey = config.minZoom.toString() +
        config.initialZoom.toString() +
        config.maxZoom.toString() +
        config.maxNativeZoom.toString() +
        JSON.stringify(config.bounds) +
        JSON.stringify(config.center);

    if (config.tileLayer) {
        uniqConfigKey += config.tileLayer.url;
    }

    return (
        <IonPage className={Style.page} style={{ borderBottom: `8px solid ${props.parkour.bgColor}` }}>
            <Header parkour={props.parkour} />
            <IonContent className={Style.container}>
                {config === null ?
                    <p className={Style.loadingText}>Loading</p>
                    :
                    <MapContainer
                        key={uniqConfigKey}
                        center={[centerLat, centerLng]}
                        maxBounds={[[config.bounds.minLatitude, config.bounds.maxLongitude], [config.bounds.maxLatitude, config.bounds.minLongitude]]}
                        maxBoundsViscosity={0.6}
                        minZoom={config.minZoom}
                        zoom={config.initialZoom}
                        maxZoom={config.maxZoom}
                        scrollWheelZoom={false}
                        className={Style.mapSize}
                    >
                        {config.tileLayer && config.tileLayer.url &&
                            <TileLayer
                                url={config.tileLayer.url}
                                attribution={config.tileLayer.attribution ? config.tileLayer.attribution : ""}
                                subdomains={config.tileLayer.subdomains ? config.tileLayer.subdomains.split(',') : ""}
                                maxNativeZoom={config.maxNativeZoom}
                                maxZoom={config.maxZoom}
                            />
                        }

                        {config.offlineGeoJson &&
                            !networkStatus &&
                            (isPlatform('ios') || !config.tileLayer!.localTilename) &&
                            config.offlineGeoJson.features.map((f: any) => (
                                <MapFeature key={f.properties.id} initialZoom={config.initialZoom} feature={f} />
                            ))}

                        {config.geoJson && config.geoJson.features.map((f: any) => (
                            <MapFeature key={f.properties.id} initialZoom={config.initialZoom} feature={f} />
                        ))}
                        <InvalidSize />
                        <MapMarkers />
                    </MapContainer>
                }
            </IonContent>
        </IonPage>
    );

};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    parkour: parkoursSelectedParkourSelector(state),
});

export default connect(mapStateToProps)(MapPage);
