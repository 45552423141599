import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage, IonRefresher, IonRefresherContent, IonSpinner, IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { GlobalStateInterface, ParkourInterface } from '../interfaces';
import { fetchParkourAction, setSelectedParkourAction } from '../store/actions';
import { parkoursIsLoadingSelector, parkoursListSelector, parkoursErrorSelector } from '../store/selectors';
import { useHistory } from 'react-router';
import { Plugins } from '@capacitor/core';
import { RefresherEventDetail } from '@ionic/core';
import ObjectImg from '../components/ObjectImg';

type PropsType = {
    isLoading: boolean,
    data: ParkourInterface[],
    error: any,

    setSelectedParkourAction: () => void,
};

const { Network } = Plugins;

const ParkourListPage: React.FC<PropsType> = ({ data, error,isLoading }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [networkStatus, setNetworkStatus] = useState(false);
    const [toast, setToast] = useState({ open: false, message: "" });

    useEffect(() => {
        Network.addListener('networkStatusChange', (status) => {
            setNetworkStatus(status.connected);
        });
        async function init() {
            let status = await Network.getStatus();
            setNetworkStatus(status.connected);

            if (data.length === 0) {
                if (status.connected) {
                    dispatch(fetchParkourAction());
                } else {
                    setToast({ open: true, message: "Aucune connexion internet" });
                }
            }
        }
        init();
    }, [dispatch, data.length])

    const doRefresh = async (e: CustomEvent<RefresherEventDetail>) => {
        if (!networkStatus) {
            setToast({ open: true, message: "Aucune connexion internet" });
            e.detail.complete();
            return;
        }

        await dispatch(fetchParkourAction());
        e.detail.complete();
    }

    const setParkour = async (index: number) => {
        dispatch(setSelectedParkourAction(index));
        history.push("/");
    }

    return (
        <IonPage>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e)}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                {isLoading &&
                    <div className="ion-text-center ion-padding">
                        <IonSpinner color='primary' />
                    </div>
                }

                {data.length === 0 && !isLoading &&
                    <p className="ion-padding ion-text-center">Aucune enquête disponible</p>
                }
                {data.map((p: ParkourInterface, index: number) => {
                    return (
                        <IonCard key={p.code}  >
                            <IonCardHeader>
                                <div className="ion-padding" style={{ backgroundColor: p.bgColor }} >
                                    <ObjectImg image={p.logo1} />
                                </div>

                                <IonCardTitle className="ion-text-center">{p.name}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <p>{p.description.substr(0, 100) + '...'}</p>
                                {p.place && <p><b>Lieu:</b> {p.place}</p>}
                                <hr />
                                <IonButton
                                    style={{ display: 'block', margin: 'auto', marginTop: '20px' }}
                                    color="light"
                                    onClick={() => setParkour(index)}
                                >
                                    Lancer cette enquête
                                </IonButton>
                            </IonCardContent>
                        </IonCard>
                    )
                })}
                {error &&
                    <IonToast isOpen={true} message={"Une erreur est survenue, veuillez vérifier votre connexion"} duration={800} />
                }
                <IonToast
                    isOpen={toast.open}
                    message={toast.message}
                    onDidDismiss={() => setToast({ open: false, message: "" })}
                    duration={800}
                />
            </IonContent>
        </IonPage>
    );
}

export default connect((state: GlobalStateInterface) => ({
    isLoading: parkoursIsLoadingSelector(state),
    data: parkoursListSelector(state),
    error: parkoursErrorSelector(state)
}))(ParkourListPage);