import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import Style from './SuccessPage.module.scss';
import { connect } from "react-redux";
import { GlobalStateInterface, ParkourInterface, SuspectInterface, TimerStateInterface } from '../interfaces';
import Header from '../components/Header';
import { parkoursSelectedParkourSelector } from '../store/selectors';
import ObjectImg from '../components/ObjectImg';

type PropsType = {
    // props
    timer: TimerStateInterface,
    parkour: ParkourInterface|null,
}

const SuccessPage: React.FC<PropsType> = (props) => {

    if(props.parkour === null){
        return <div>Loading</div>
    }

    const getSeconds = `0${(props.timer.time % 60)}`.slice(-2)
    const minutes = `${Math.floor(props.timer.time / 60)}`
    const getMinutes = `0${parseInt(minutes) % 60}`.slice(-2)
    const getHours = `0${Math.floor(props.timer.time / 3600)}`.slice(-2)

    return (
        <IonPage>
            <Header parkour={props.parkour} />
            <IonContent>
                <div className={Style.container}>
                    <div className={Style.guiltyContainer} style={{ backgroundColor: props.parkour.color1 }}>
                        <div className={Style.guiltyCircle}>
                            {props.parkour.suspects.map((suspect:SuspectInterface, index: number) => {
                                if (suspect.guilty) {
                                    return <ObjectImg image={suspect.avatar} alt={`suspect ${suspect.name}`} key={index} />
                                }
                                return '';
                            })}
                        </div>
                    </div>
                    <div className={Style.successTextContainer} style={{ color: props.parkour.bgColor }}>
                        <p className={Style.successText}>BRAVO !</p>
                        <div className={Style.timeCdContainer}>
                            <p className={Style.timerCd} style={{ color: props.parkour.color1 }}>{getHours + ' : ' + getMinutes + ' : ' + getSeconds}</p>
                        </div>
                        <p className={Style.finalMessage}>{props.parkour.successMessage}</p>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );

};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    timer: state.timer,
    parkour: parkoursSelectedParkourSelector(state),
});

export default connect(mapStateToProps)(SuccessPage);
