import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { timerTickAction } from '../store/actions/timerActions';
import { GlobalStateInterface, TimerStateInterface } from '../interfaces';

type PropsType = {
    timer: TimerStateInterface,
    timerTickAction: () => void
}

const Timer: React.FC<PropsType> = (props) => {

    useEffect(() => {
        if (props.timer.onGoing === true) {
            var myTimer = setTimeout(() => {
                props.timerTickAction()
            }, 1000)
            return () => clearTimeout(myTimer)
        }
    })

    return (
        <> </>
    );

};

// RECUP DU STORE REDUX
const mapStateToProps = (state: GlobalStateInterface) => ({
    timer: state.timer,
});

const mapDispatchToProps = {
    timerTickAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
