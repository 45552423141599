import { createSelector } from 'reselect';
import { GlobalStateInterface } from '../../interfaces';

export const parkoursSelector = (state: GlobalStateInterface) => state.parkours !== undefined ? state.parkours : null;

export const parkoursIsLoadingSelector = createSelector(
    [parkoursSelector],
    parkour => parkour ? parkour.loading : false
);

export const parkoursIsLocallyLoadedSelector = createSelector(
    [parkoursSelector],
    parkour => parkour ? parkour.locallyLoaded : false
);

export const parkoursPercentSelector = createSelector(
    [parkoursSelector],
    parkour => parkour ? parkour.progressPercents : null
);

export const parkoursErrorSelector = createSelector(
    [parkoursSelector],
    parkour => parkour ? parkour.error : null
);

export const parkoursListSelector = createSelector(
    [parkoursSelector],
    parkour => parkour ? parkour.data : []
);
export const parkourSelectedParkourIndexSelector = createSelector(
    [parkoursSelector],
    parkour => parkour ? parkour.selectedParkour : null
);
export const parkoursSelectedParkourSelector = createSelector(
    [parkoursListSelector, parkourSelectedParkourIndexSelector],
    (parkourData, index) => index !== null && parkourData ? parkourData[index] : null
);

export const parkourspoiDiscoveredSelector = createSelector(
    [parkoursSelector],
    parkour => parkour ? parkour.poiDiscovered : []
);