import React from 'react';

const ObjectImg = (props: any) => {
    const { image, alt, ...rest } = props;
    if (!image.base64) {
        return <></>
    }

    return <img src={props.image.base64} alt={alt} {...rest} />
}

export default ObjectImg;